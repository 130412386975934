import {
  Box,
  Center,
  Grid,
  Heading,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Frame } from '../index'

type FeatureData = {
  iconURI: string
  title: string
  description: string
}

const featureList: FeatureData[] = [
  {
    iconURI: '/landing/Image/transcription.png',
    title: '自動文字起こし',
    description:
      'AIを使用した音声認識により、\n発話をリアルタイムで文字起こし。\n作業時間を大幅に削減できます。',
  },
  {
    iconURI: '/landing/Image/aisummary.png',
    title: 'AI自動要約',
    description:
      '発話をAIが自動で高精度要約。\n要点を箇条書きでまとめて記録して、\n議事録作成を劇的に効率化します。',
  },
  {
    iconURI: '/landing/Image/zoom-integrate.png',
    title: 'Zoom連携',
    description:
      'AI議事録取れる君をZoomミーティングに\n招待し、参加者の発話を記録できます。\nスケジュール連携により自動化も可能。',
  },
  {
    iconURI: '/landing/Image/msteams-integrate.png',
    title: 'Microsoft Teams連携*',
    description:
      'AI議事録取れる君をMicrosoft Teams会議\nに招待し、参加者の発話を記録できます。\nスケジュール連携により自動化も可能。',
  },
  {
    iconURI: '/landing/Image/googlemeet-integrate.png',
    title: 'Google Meet連携',
    description:
      'AI議事録取れる君をGoogle Meet会議\nに招待し、参加者の発話を記録できます。\nスケジュール連携により自動化も可能。',
  },
  {
    iconURI: '/landing/Image/audio-file-import-after.png',
    title: 'ファイル取込',
    description:
      '音声ファイルや動画ファイルを\nインポートし、議事録を作成できます。\n話者識別も可能です。',
  },
  {
    iconURI: '/landing/Image/words.png',
    title: '単語登録',
    description:
      '聞き取ってほしい専門用語や、\nAIが知らない固有名詞などを登録して、\n認識精度を高めることができます。',
  },
  {
    iconURI: '/landing/Image/translate.png',
    title: '翻訳',
    description:
      '発話言語と翻訳言語を指定して翻訳。\n90以上の国と地域の発話を、\n主要な16の言語に翻訳できます。',
  },
  {
    iconURI: '/landing/Image/edit.png',
    title: '共同編集',
    description:
      '参加者全員で議題や要約の作成、\n文字起こしの微修正などを共同編集。\n会議のまとめを短時間で効率的に。',
  },
  {
    iconURI: '/landing/Image/summary.png',
    title: 'ブックマーク',
    description:
      '会議中の重要な発話をブックマーク。\n自由に加筆・修正・削除して、\n議論をリアルタイムで整理できます。',
  },
  {
    iconURI: '/landing/Image/team.png',
    title: 'チーム',
    description:
      '議事録を共有するメンバーでチームを\n作成。チーム内で作成した議事録は、\nいつでもメンバーが閲覧・編集できます。',
  },
  {
    iconURI: '/landing/Image/recording.png',
    title: '自動録音',
    description:
      '発話を自動的に録音、\n聞き直したい発話を選んで再生。\n確認作業も効率化できます。',
  },
  {
    iconURI: '/landing/Image/download.png',
    title: 'ダウンロード',
    description:
      '作成した議事録をダウンロード。\nワード、CSV、マークダウン形式\nが選べ、簡単に編集・共有できます。',
  },
  {
    iconURI: '/landing/Image/share.png',
    title: '共有',
    description:
      '作成した議事録はURLで簡単共有、\nリンク開くだけでアクセス可能。\nアクセス制御も設定できます。',
  },
  {
    iconURI: '/landing/Image/mail.png',
    title: 'メール展開',
    description:
      '参加者に議事録をメールで展開。\n議事録の記録が完了すると、\nすぐにメールが届きます。',
  },
]

const Feature = ({ data }: { data: FeatureData }) => {
  const [iconDimension, setIconDimension] = useState<
    [number, number] | undefined
  >()
  const [isFirstRendering, setIsFirstRendering] = useState(true)
  useEffect(() => {
    setIsFirstRendering(false)
  }, [])
  return (
    <VStack
      spacing={0}
      align={{ base: 'center', md: 'flex-start' }}
      textAlign={{ base: 'center', md: 'start' }}
      width="19rem"
    >
      <Center>
        <Image
          // For bug of Chrome  ref: https://bugs.chromium.org/p/chromium/issues/detail?id=7731
          src={isFirstRendering ? '' : data.iconURI}
          onLoad={(ev) => {
            setIconDimension([ev.currentTarget.width, ev.currentTarget.height])
          }}
          pos="relative"
          width="100%"
        />
      </Center>
      <Box mt={{ base: '0.5rem !important', md: '1.5rem !important' }} />
      <Text
        fontWeight="bold"
        fontSize="1.75rem"
        lineHeight="none"
        width="width"
        alignSelf="center"
      >
        {data.title}
      </Text>
      <Box mt={{ base: '1rem !important', md: '1.5rem !important' }} />
      <Text
        fontSize="1rem"
        lineHeight="150%"
        whiteSpace="pre-line"
        width="100%"
        textAlign="center"
      >
        {data.description}
      </Text>
    </VStack>
  )
}

export const Features = () => {
  return (
    <Frame py={{ base: '5rem', md: '10rem' }}>
      <VStack spacing="0rem">
        <VStack alignItems="flex-start" spacing={2.5} alignSelf="flex-start">
          <Heading id="機能" variant="main">
            Features
          </Heading>
          <Text variant="mainHeadingDescription">機能</Text>
        </VStack>
        <Spacer flexBasis={{ base: '2rem', md: '3.5rem' }} />
        <Grid
          w="full"
          justifyItems={'center'}
          rowGap={{ base: '4rem', md: '3rem' }}
          textAlign="center"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            '2xl': 'repeat(3, 1fr)',
          }}
          maxW={{ base: '30rem', md: 'none' }}
        >
          {featureList.map((data, i) => {
            return <Feature data={data} key={i} />
          })}
        </Grid>
      </VStack>
      <Center>
        <Text mt="4rem">
          * 一般法人向けMicrosoft
          365にてご利用いただけます。家庭向け、または無料のMicrosoft
          Teamsは対象外となります。
        </Text>
      </Center>
    </Frame>
  )
}
