import React from 'react'
import { Frame } from '../index'
import { Pricing as PricingCard } from '../../Organisms/Card/Pricing'
import { DisplayPlan } from '@gijirokukun/shared'
import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import { ITEM_LISTS } from 'src/api/analytics'
import { App } from 'src/api/firebase'

export const Pricing: React.FC<{
  onClick: (plan: DisplayPlan) => void
}> = ({ onClick }) => {
  const PlanButton: React.FC<{ plan: DisplayPlan; dataTest: string }> = ({
    plan,
    dataTest,
  }) => {
    return (
      <>
        {plan.canTrialOnWeb ? (
          <Button
            data-test={`${dataTest}-free`}
            colorScheme="primary"
            bg="secondary"
            variant="outline"
            w="13.44rem"
            h="3.5rem"
            borderRadius="2rem"
            onClick={() => {
              onClick(plan)
            }}
            fontWeight="bold"
            fontSize="0.875rem"
            lineHeight="none"
            color="primary"
          >
            無料で試してみる
          </Button>
        ) : (
          <Button
            data-test={`${dataTest}-${plan.type}`}
            colorScheme="primary"
            variant="solid"
            w="13.44rem"
            h="3.5rem"
            borderRadius="2rem"
            onClick={() => {
              if (plan.canTrialOnWeb) {
                onClick(plan)
              } else {
                location.href = '#お問い合わせ'
              }
            }}
            fontWeight="bold"
            fontSize="0.875rem"
            lineHeight="none"
            color="white"
          >
            詳細お問い合わせ
          </Button>
        )}
      </>
    )
  }

  return (
    <Frame py={{ base: '5rem', md: '10rem' }}>
      <VStack spacing="0.625rem" align="flex-start">
        <Heading id="価格" variant="main">
          Pricing
        </Heading>
        <Text variant="mainHeadingDescription">ご利用料金</Text>
      </VStack>
      <PricingCard
        PlanButton={PlanButton}
        defaultTab="organization"
        onChangeExpanded={(isExpanded) =>
          isExpanded
            ? App.analytics.logEvent('landing_plan_expanded')
            : undefined
        }
        onVisible={() => {
          App.analytics.logEvent('landing_scroll_to_pricing')
          App.analytics.logEvent(
            'view_item_list',
            ITEM_LISTS['LANDING_PLAN_LIST']
          )
        }}
      />
    </Frame>
  )
}
