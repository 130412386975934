export const remToPixel = (rem: number) => {
  return (
    rem *
    (process.browser
      ? parseFloat(getComputedStyle(document.documentElement).fontSize)
      : 16)
  )
}

export const remStrToPixel = (rem: string) => {
  const _rem = parseInt(rem.replace('rem', ''))
  return remToPixel(_rem)
}

export const remStrToRem = (rem: string) => {
  return parseInt(rem.replace('rem', ''))
}

export const pixelToRem = (pixel: number) => {
  return (
    pixel /
    (process.browser
      ? parseFloat(getComputedStyle(document.documentElement).fontSize)
      : 16)
  )
}
