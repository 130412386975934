import { HStack, Image, Link, Spacer, Text, VStack } from '@chakra-ui/react'
import NextLink from 'next/link'
import React from 'react'
import { routes } from 'src/constants/routes'

export const Footer = () => {
  return (
    <VStack bg="black" spacing="2.5rem" py="2.5rem">
      <HStack spacing="2.5rem">
        <NextLink href={routes.terms} passHref>
          <Link>
            <Text
              fontSize="1rem"
              lineHeight="none"
              color="white"
              cursor="pointer"
            >
              利用規約
            </Text>
          </Link>
        </NextLink>
        <NextLink href={routes.privacy} passHref>
          <Link>
            <Text
              fontSize="1rem"
              lineHeight="none"
              color="white"
              cursor="pointer"
            >
              プライバシーポリシー
            </Text>
          </Link>
        </NextLink>
        <NextLink href={routes.security} passHref>
          <Link>
            <Text
              fontSize="1rem"
              lineHeight="none"
              color="white"
              cursor="pointer"
            >
              情報セキュリティ基本方針
            </Text>
          </Link>
        </NextLink>
        <NextLink
          href="https://fresh-join-648.notion.site/68ed99bb9ff74c15939e27dc8d0ca083"
          passHref
        >
          <Link>
            <Text
              fontSize="1rem"
              lineHeight="none"
              color="white"
              cursor="pointer"
            >
              特定商取引法に基づく表記
            </Text>
          </Link>
        </NextLink>
        <NextLink href={routes.analytics} passHref>
          <Link>
            <Text
              fontSize="1rem"
              lineHeight="none"
              color="white"
              cursor="pointer"
            >
              アクセス解析ツールについて
            </Text>
          </Link>
        </NextLink>
      </HStack>
      <Spacer flexBasis="1.25rem" mt="0 !important" />
      <NextLink href="/" passHref>
        <Link>
          <Image
            src="/landing/Logo/Logo-white.svg"
            alt="AI議事録取れる君"
            h="2rem"
            cursor="pointer"
          />
        </Link>
      </NextLink>
      <Link href="https://alm.ne.jp/" target="_blank">
        <Text
          fontSize="0.875rem"
          lineHeight="192.857142857%"
          color="white"
          cursor="pointer"
        >
          {`© ${new Date().getFullYear()} ALM`}
        </Text>
      </Link>
    </VStack>
  )
}
