import {
  Box,
  BoxProps,
  Button,
  Flex,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Select,
  Spacer,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { App } from 'src/api/firebase'
import { Functions } from '@gijirokukun/shared'
import * as yup from 'yup'
import { FormControl } from '../components'
import { Frame } from '../index'
import {
  phoneNumberRegex,
  phoneNumberRegexFailedMessage,
} from '@gijirokukun/shared'
import { call } from 'src/api/firebase'

const schema = yup.object({
  companyName: yup.string().required('会社名を入力してください'),
  name: yup.string().required('名前を入力してください'),
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('無効なメールアドレスです'),
  phoneNumber: yup.string().matches(phoneNumberRegex, {
    message: phoneNumberRegexFailedMessage,
    excludeEmptyString: true,
  }),
  /*
    preferredPlan: yup
      .string()
      .required('ご希望のプランを選択してください')
      .oneOf(['Pro', 'Business'], 'ご希望のプランを選択してください'),
    numberOfGuests: yup
      .string()
      .required('導入予定人数を入力してください')
      .matches(/^[0-9０-９]*$/, '導入予定人数は整数で入力してください')
      .matches(/^.*[^0０]+.*$/, '導入予定人数は1人以上を指定してください'),
    */
  category: yup.string().required('問い合わせ種別を選択してください'),
  details: yup.string().required('お問い合わせ内容を入力してください'),
})

type ContactSchema = yup.InferType<typeof schema>

export const Contact: FC<BoxProps> = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactSchema>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const { addToast } = useToasts()
  const [sending, setSending] = useState(false)

  const onSubmit = (values: ContactSchema) => {
    if (sending) {
      return
    }
    setSending(true)
    const sendInquiry = call('sendInquiry')
    sendInquiry(values)
      .then(() => {
        reset()
        App.analytics.logEvent('send_inquiry')
        addToast('お問い合わせを送信しました！', { appearance: 'success' })
      })
      .catch((err) => {
        console.error(err)
        addToast(
          '不明なエラーが発生しました。お問い合わせが送信できませんでした。',
          {
            appearance: 'error',
          }
        )
      })
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <Frame py={{ base: '5rem', md: '10rem' }} {...props}>
      <Flex flexDir="column">
        <VStack spacing={2.5} align="flex-start">
          <Heading id="お問い合わせ" variant="main">
            CONTACT
          </Heading>
          <Text variant="mainHeadingDescription">お問い合わせ</Text>
        </VStack>
        <Spacer flexBasis={{ base: '2rem', md: '3.5rem' }} />
        <Text
          fontSize={{ base: '0.875rem', md: '1rem' }}
          px={{ base: 0, md: '6rem' }}
        >
          導入に関するお問い合わせや、セールスパートナーに関するお問い合わせはこちらからお寄せ下さい。
        </Text>
        <Text
          fontSize={{ base: '0.875rem', md: '1rem' }}
          px={{ base: 0, md: '6rem' }}
        >
          本サービスの販売及びサポートを担当しております株式会社ALMよりご連絡させていただきます。
          <NextLink href="https://alm.ne.jp/privacy/" passHref>
            <Link textColor="primary">プライバシーポリシー（株式会社ALM）</Link>
          </NextLink>
        </Text>
        <Spacer flexBasis={{ base: '1rem', md: '2rem' }} />
        <form>
          <VStack spacing="1rem" px={{ base: 0, md: '5rem' }}>
            <FormControl
              isRequired
              isInvalid={errors.companyName?.message != null}
            >
              <FormLabel variant="grid">会社名</FormLabel>
              <Input
                variant="filled"
                autoComplete="organization"
                placeholder="個人の場合は個人とご記入ください"
                {...register('companyName')}
              />
              <FormErrorMessage>{errors.companyName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.name?.message != null}>
              <FormLabel variant="grid">名前</FormLabel>
              <Input
                variant="filled"
                autoComplete="name"
                {...register('name')}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.email?.message != null}>
              <FormLabel variant="grid">メールアドレス</FormLabel>
              <Input
                variant="filled"
                type="email"
                autoComplete="email"
                {...register('email')}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.phoneNumber?.message != null}>
              <FormLabel variant="grid">電話番号</FormLabel>
              <Input
                variant="filled"
                type="tel"
                autoComplete="tel"
                {...register('phoneNumber')}
              />
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={errors.category?.message != null}
            >
              <FormLabel variant="grid">問い合わせ種別</FormLabel>
              <Select
                variant="filled"
                placeholder="選択してください"
                {...register('category')}
              >
                <option>資料請求</option>
                <option>サービスの説明がききたい</option>
                <option>オンラインデモの希望</option>
                <option>価格に関する問い合わせ（見積依頼）</option>
                <option>操作方法について</option>
                <option>契約している内容の確認をしたい</option>
                <option>販売パートナー協業について</option>
                <option>その他</option>
              </Select>
              <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
            </FormControl>
            {/*
            <FormControl
              isRequired
              isInvalid={errors.numberOfGuests?.message != null}
            >
              <FormLabel variant="grid">導入予定人数</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Text color="gray">約</Text>
                </InputLeftElement>
                <Input variant="filled" {...register('numberOfGuests')} />
                <InputRightElement pointerEvents="none">
                  <Text color="gray">人</Text>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.numberOfGuests?.message}
              </FormErrorMessage>
            </FormControl>
            */}
            <FormControl isRequired isInvalid={errors.details?.message != null}>
              <FormLabel variant="grid">お問い合わせ内容</FormLabel>
              <Textarea
                variant="filled"
                h="10.3125rem"
                {...register('details')}
              ></Textarea>
              <FormErrorMessage>{errors.details?.message}</FormErrorMessage>
            </FormControl>
            <Box />
            <Button
              colorScheme="black"
              variant="solid"
              w="13.4375rem"
              h="3.5rem"
              borderRadius="2rem"
              onClick={handleSubmit(onSubmit)}
              fontWeight="bold"
              fontSize="0.875rem"
              lineHeight="none"
              color="white"
              isLoading={sending}
            >
              送信する
            </Button>
          </VStack>
        </form>
      </Flex>
    </Frame>
  )
}
