import { useTransform, useScroll } from 'framer-motion'
import React from 'react'
import { MotionImage } from '../components'

export const Background = () => {
  const { scrollY } = useScroll()
  const offsetY1 = useTransform(scrollY, (y) => `calc(-37% + ${y / 5}px)`)
  const offsetY2 = useTransform(scrollY, (y) => `calc(-35.6% + ${y / 5}px)`)

  return (
    <>
      <MotionImage
        src="/landing/Illust/Vector.svg"
        pos="absolute"
        left="-2%"
        style={{ top: offsetY1 as any }}
        zIndex="hide"
      />
      <MotionImage
        src="/landing/Illust/Vector.svg"
        pos="absolute"
        left="1%"
        style={{ top: offsetY2 as any }}
        zIndex="hide"
        opacity="0.3"
      />
    </>
  )
}
