import {
  Box,
  Flex,
  Image,
  Img,
  Text,
  useBreakpoint,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { Video } from '../components'
import { Frame } from '../index'

export const Pop = () => {
  const bp = useBreakpoint('base')!
  const isMovieSmall = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    'md'
  )!
  return (
    <Frame pt={['3rem', '5.5rem', '7.5rem']} pb={['6rem', '4rem', '2rem']}>
      <VStack spacing="2rem" textAlign="center">
        <Text
          color="primary"
          fontSize="2.5rem"
          fontWeight="black"
          lineHeight="125%"
        >
          AIによる
          {bp === 'base' ? <br /> : undefined}
          自動文字起こし
        </Text>
        {/* // 日本一ではないので一旦消す
        <Image
          src="/landing/Icon/badge_speech.svg"
          height={['5.25rem', '6rem', '6.5625rem']}
        />
        */}
        <VStack spacing="0.25rem">
          <Text
            fontSize={['1rem', '1.125rem', '1.25rem']}
            lineHeight="200%"
            textAlign="left"
          >
            マイクからの音声をAIが即座にテキスト化、議事録作成の効率化を実現します。
          </Text>
        </VStack>
        <Flex pos="relative" w="full" mx="0.625rem">
          <Box w="full" pb="71.25%" />
          <Image
            src="/landing/Wrapper/Frame.png"
            w="full"
            h="auto"
            zIndex="2"
            pos="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
          />
          <Video
            key={isMovieSmall ? 1 : 0}
            pos="absolute"
            top="10%"
            left="7%"
            w="86%"
            loop
            autoPlay
            muted
            playsInline
            borderRadius="2.5%"
            boxShadow="inset 0px 0px 1px rgba(0, 0, 0, 0.225415)"
            zIndex={2}
          >
            <source
              src={
                isMovieSmall
                  ? '/landing/Movie/demo_sp.webm'
                  : '/landing/Movie/demo_pc.webm'
              }
            ></source>
            <source
              src={
                isMovieSmall
                  ? '/landing/Movie/demo_sp.mp4'
                  : '/landing/Movie/demo_pc.mp4'
              }
            ></source>
          </Video>
        </Flex>
      </VStack>
      <VStack spacing="2rem" textAlign="center">
        <Text
          color="primary"
          fontSize="2.5rem"
          fontWeight="black"
          lineHeight="125%"
        >
          AIによる自動要約
        </Text>
        <VStack spacing="0.25rem">
          <Text
            fontSize={['1rem', '1.125rem', '1.25rem']}
            lineHeight="200%"
            textAlign="left"
          >
            文字起こしが終了すると、テキストをAIが解析して小見出しと箇条書きの要約を自動的に作成します。
          </Text>
        </VStack>
        <Flex pos="relative" w="full" mx="0.625rem">
          <Box w="full" pb="71.25%" />
          <Image
            src="/landing/Wrapper/Frame.png"
            w="full"
            h="auto"
            zIndex="2"
            pos="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
          />
          <Img
            src="/landing/Image/summarize-pop.png"
            pos="absolute"
            top="10%"
            left="7%"
            w="86%"
            borderRadius="2.5%"
            boxShadow="inset 0px 0px 1px rgba(0, 0, 0, 0.225415)"
            zIndex={2}
          />
        </Flex>
      </VStack>
    </Frame>
  )
}
