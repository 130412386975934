import {
  Box,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  useBreakpointValue,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import useEventListener from '@use-it/event-listener'
import { useTransform, useScroll } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutationObserver } from 'rooks'
import { MotionHStack, Span } from '../components'
import { HEADER_HEIGHT_BREAKPOINT } from 'src/components/Organisms/Headers/GeneralHeader'
import { FRAME_BREAKPOINT, SIGNUP_CARD_WIDTH_BREAKPOINT } from '../index'
import { pixelToRem, remStrToPixel, remStrToRem } from '../utils'

type SceneData = {
  picURI: string
  title: JSX.Element
  description: string
}

const sceneList: SceneData[] = [
  {
    picURI: '/landing/Illust/illust-online-meeting.svg',
    title: (
      <>
        <Span color="primary">オンライン</Span>
        <br />
        ミーティング
      </>
    ),
    description: 'リンク１つで簡単にゲストを招待し、議事録を共同編集できます。',
  },
  {
    picURI: '/landing/Illust/illust-solo-writing.svg',
    title: (
      <>
        <Span color="primary">社内</Span>会議
        <br />
        <br />
      </>
    ),
    description:
      'チーム機能を使うと、チーム内で議事録が共有され簡単に参加・閲覧できます。',
  },
  {
    picURI: '/landing/Illust/illust-multilingual-person.svg',
    title: (
      <>
        <Span color="primary">多言語</Span>
        <br />
        ミーティング
      </>
    ),
    description:
      '自動翻訳機能を使うと、発言ごとに翻訳結果が一緒に表示されます。',
  },
  {
    picURI: '/landing/Illust/illust-offline-meeting.svg',
    title: (
      <>
        <Span color="primary">対面</Span>での
        <br />
        打ち合わせ
      </>
    ),
    description: '発話が自動的に録音・文字起こしされ、後から確認できます。',
  },
]

const UseScene: React.FC<{ data: SceneData; index: number }> = ({
  data,
  index,
}) => {
  const [_isSmallPhone] = useMediaQuery('(max-height: 750px)') //FIXME: SSGのためにCSSにする
  const [isSmallPhone, setIsSmallPhone] = useState(true)
  useEffect(() => {
    setIsSmallPhone(_isSmallPhone)
  }, [_isSmallPhone])
  return (
    <Flex>
      <VStack
        bg="white"
        borderRadius="1.5rem"
        p={{ base: '1.25rem', md: '2rem' }}
        pos="relative"
        alignItems="flex-start"
        w={{
          base: `calc(100vw - ${FRAME_BREAKPOINT[0]} * 2)`,
          md: '25.875rem',
        }}
        minH={
          isSmallPhone
            ? { base: '15rem', md: '19rem' }
            : { base: '23.5rem', md: '35.125rem' }
        }
        spacing={{ base: '0.5rem', md: '1rem' }}
      >
        <Flex
          pt={{ base: '0.875rem', md: '1.4375rem' }}
          alignItems="center"
          pos="absolute"
          left="-0.625rem"
          top="-0.625rem"
          bg="white"
          borderRadius="full"
          borderWidth="0.25rem"
          borderColor="primary"
          flexDir="column"
          boxSize={{ base: '5rem', md: '6.875rem' }}
        >
          <Text
            color="primary"
            fontFamily="Rubik"
            fontWeight="bold"
            lineHeight="none"
            fontSize={{ base: '1rem', md: '1.5rem' }}
          >
            Scene
          </Text>
          <Text
            fontSize={{ base: '1.75rem', md: '2.25rem' }}
            color="primary"
            fontFamily="Rubik"
            fontWeight="bold"
            lineHeight="none"
          >
            {(index + 1).toString().padStart(2, '0')}
          </Text>
        </Flex>
        {isSmallPhone ? (
          <Flex flexDir="row">
            <Image
              src={data.picURI}
              h="5rem"
              minW="6.25rem"
              alignSelf="center"
            />
            <Flex flexDir="column">
              <Text
                fontSize={{ base: '1.75rem', md: '2.5rem' }}
                fontWeight="bold"
                lineHeight="130%"
                mb="1rem"
              >
                {data.title}
              </Text>
              <Text lineHeight="200%">{data.description}</Text>
            </Flex>
          </Flex>
        ) : (
          <>
            <Image
              src={data.picURI}
              h={{ base: '10rem', md: '15.75rem' }}
              alignSelf="center"
            />
            <Spacer flexBasis={{ base: '0.5rem', md: '0.875rem' }} />
            <Text
              fontSize={{ base: '1.75rem', md: '2.5rem' }}
              fontWeight="bold"
              lineHeight="130%"
            >
              {data.title}
            </Text>
            <Text lineHeight="200%">{data.description}</Text>
          </>
        )}
      </VStack>
    </Flex>
  )
}

export const UseSceneList: React.FC = () => {
  // （内部的には縦に長い）青色の枠全体
  const outerRef = useRef<HTMLDivElement>(null)
  // （内部的には横に長い）カードのリスト
  const innerRef = useRef<HTMLElement>(null)

  // 計算用の周囲のコンポーネントの長さ
  const headerHeight = useBreakpointValue(HEADER_HEIGHT_BREAKPOINT, 'md')!
  const frameWidth = useBreakpointValue(FRAME_BREAKPOINT, 'md')!
  const signupCardWidth = useBreakpointValue(
    SIGNUP_CARD_WIDTH_BREAKPOINT,
    'md'
  )!

  const { scrollY } = useScroll()

  // 横スクロール速度
  const scrollSpeed = 0.6 / 250

  // 横スクロールが始まるOffset
  const [scrollBeginHeightOffset, setScrollBeginHeightOffset] =
    useState<number>()
  // 横スクロール量
  const [scrollSumWidth, setScrollSumWidth] = useState<number>()
  const adjust = useCallback(() => {
    if (innerRef.current && outerRef.current) {
      // Use sceneが画面の一番上に着いた時にスクロールが始まるようにする
      setScrollBeginHeightOffset(
        outerRef.current.offsetTop - remStrToPixel(headerHeight)
      )
      // 1つ目のコンポーネントが左端に表示されている状態から最後のコンポーネントが右端に表示されている状態になるまでの距離
      const windowWidth = window.innerWidth - remStrToPixel(signupCardWidth)
      const cardListWidth = innerRef.current.clientWidth
      setScrollSumWidth(
        Math.max(
          0,
          cardListWidth - (windowWidth - remStrToPixel(frameWidth) * 2)
        )
      )
    }
  }, [frameWidth, headerHeight, signupCardWidth])
  useEffect(() => {
    adjust()
  }, [adjust, headerHeight])
  useMutationObserver(innerRef, adjust)
  useMutationObserver(outerRef, adjust)
  useEventListener('resize', adjust)

  // 横の内部的な総長
  const scrollSumHeight = sceneList.length / scrollSpeed

  // 横スクロールの計算処理
  // 縦はBeginを０％、Begin+Sumを１００％に設定する
  const input =
    scrollBeginHeightOffset != null
      ? [scrollBeginHeightOffset, scrollBeginHeightOffset + scrollSumHeight]
      : [0, 0]
  // 横はscrollSumWidth
  const outputX = scrollSumWidth != null ? [0, -scrollSumWidth] : [0, 0]
  const sceneOffsetX = useTransform(scrollY, input, outputX)

  return (
    <Box w="100%" ref={outerRef} id="利用シーン">
      <Box bg="primary" w="100%" alignSelf="center">
        <Box
          pos="relative"
          py="2.5rem"
          h={`${50 + pixelToRem(scrollSumHeight)}rem`}
        >
          <Box top={`${remStrToRem(headerHeight) + 2.5}rem`} pos="sticky">
            <VStack alignItems="flex-start" spacing="2rem" overflowX="hidden">
              <Box pos="relative" left={FRAME_BREAKPOINT}>
                <VStack
                  bottom={'20rem'}
                  alignItems="flex-start"
                  spacing="0.625rem"
                >
                  <Heading variant="main" color="white">
                    Use scene
                  </Heading>
                  <Text variant="mainHeadingDescription" color="white">
                    利用シーン
                  </Text>
                </VStack>
                <Box mt="2rem" />
                <MotionHStack
                  ref={innerRef}
                  pos="relative"
                  spacing="2.5rem"
                  style={{
                    top: 0,
                    left: sceneOffsetX as any,
                  }}
                >
                  {sceneList.map((data, i) => {
                    return <UseScene data={data} key={i} index={i} />
                  })}
                </MotionHStack>
              </Box>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
