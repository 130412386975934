import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'src/api/router'
import React from 'react'
import { DisplayPlan } from '@gijirokukun/shared'
import { signInWithRedirectToGoogle } from 'src/models/user'
import { routes, useURL } from 'src/constants/routes'
import { theme } from '../theme'
import { SignupForm } from 'src/components/Organisms/Form/SignUpForm'

export const SignupCard: React.FC<{
  isModal: boolean
  onClose?: () => void
  selectedPlan?: DisplayPlan | undefined
  callbackURL?: string
}> = (props) => {
  const router = useRouter()
  const { toURL } = useURL()

  const signUpWithGoogle = () => {
    if (!toURL) {
      console.error('Unexpected')
      return
    }
    signInWithRedirectToGoogle(
      router,
      'signUp',
      props.callbackURL ?? toURL(routes.comments),
      false
    )
  }

  const [isTall] = useMediaQuery('(min-height: 920px)')

  return (
    <Flex justify="center" bg="white" w="100%" h="full" minH="800px">
      <Box maxW="40rem" flexGrow={1} h="full">
        {props.isModal && (
          <Image
            pos="fixed"
            src="/landing/Icon/close.svg"
            boxSize="1rem"
            right="1.5rem"
            top="1.5rem"
            onClick={props.onClose}
            cursor="pointer"
          />
        )}
        <VStack
          spacing={isTall ? '1.5rem' : '0.5rem'}
          px="1.25rem"
          bg="white"
          h="full"
          justify="center"
        >
          <Text
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="130%"
            whiteSpace="pre-line"
            textAlign="center"
          >
            Personalプランを
            <br />
            無料体験
          </Text>
          <Divider w="6rem" />
          <Button
            className="btn-icon"
            variant="outline"
            color="#333"
            onClick={signUpWithGoogle}
            borderRadius="full"
            w="full"
            flexShrink={0}
          >
            <span className="btn-inner--icon">
              <img
                data-test={`${
                  props.isModal ? 'modal' : 'card'
                }-signUp-button-googleSighIn"`}
                alt="Googleで登録"
                src={'/images/common/google.svg'}
              />
            </span>
            <span className="btn-inner--text">Googleで登録</span>
          </Button>
          <Text fontSize="0.875rem" lineHeight="none">
            または
          </Text>
          <Box w="100%">
            <SignupForm
              callbackURL={props.callbackURL}
              selectedPlan={props.selectedPlan}
            />
          </Box>
          <VStack
            bg="white"
            spacing="1.5rem"
            pb="0.5rem"
            boxShadow={`0rem -0.0625rem 0rem ${theme.colors.lightGray}`}
          >
            <Text fontSize="0.875rem" lineHeight="none">
              {/* eslint-disable-next-line no-irregular-whitespace */}
              または　
              <NextLink href={routes.login} passHref>
                <Link
                  color="primary"
                  data-test={`${
                    props.isModal ? 'modal' : 'card'
                  }-signUpCard-login-link`}
                >
                  ログイン
                </Link>
              </NextLink>
            </Text>
          </VStack>
        </VStack>
      </Box>
    </Flex>
  )
}

export const ModalSignupCard: React.FC<{
  isOpen: boolean
  handleClose: () => void
  callbackURL?: string
  selectedPlan: DisplayPlan | undefined
}> = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.handleClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        h="100vh"
        w="100vw"
        maxW="none"
        alignItems="center"
        my={0}
        borderRadius={0}
      >
        <SignupCard
          isModal
          onClose={props.handleClose}
          selectedPlan={props.selectedPlan}
          callbackURL={props.callbackURL}
        />
      </ModalContent>
    </Modal>
  )
}
