import {
  BoxProps,
  Center,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { MotionBox, MotionImage, MotionVStack } from '../components'
import { Frame } from '../index'

const faqList: [string, string][] = [
  [
    '利用可能なブラウザを教えてください。',
    'Chrome, Safari, Edgeに対応しています。',
  ],
  [
    '議事録にパスワードを設定できますか？',
    '可能です。議事録作成時にパスワード入力してください。',
  ],
  [
    'ZoomやMS Teamsなどのオンライン会議のツールと併用できますか？',
    'ブラウザで開くアプリのため、ZoomとMS Teams、Google meets、webexなどのオンライン会議ツールと併用できます。',
  ],
  [
    'サブスクリプションはどのように更新されますか？',
    '契約更新日に自動的に更新されます。 サブスクリプションを中止したい場合は、契約更新日より前にアカウント情報ページの「管理」ボタンからキャンセルしてください。',
  ],
]

const FAQ = ({
  q,
  a,
  opened,
  onClick,
}: {
  q: string
  a: string
  opened: boolean
  onClick: () => void
}) => {
  const animate = opened ? 'open' : 'closed'
  return (
    <MotionVStack
      spacing={{ base: '1rem', md: '2rem' }}
      borderRadius="1.875rem"
      p="1.25rem"
      w="full"
      align={{ base: 'center', md: 'flex-start' }}
      cursor="pointer"
      onClick={onClick}
      animate={animate}
      variants={{
        open: {
          backgroundColor: '#ECEFF5FF',
        },
        closed: {
          backgroundColor: '#ECEFF500',
        },
      }}
    >
      <Flex justify="space-between" w="full" align="center">
        <Text
          fontWeight="bold"
          fontSize={{ base: '1.125rem', md: '1.5rem' }}
          lineHeight="130%"
        >
          {q}
        </Text>
        <Center boxSize={{ base: '1.125rem', md: '1.5rem' }} pos="relative">
          <MotionImage
            pos="absolute"
            src={'/landing/Icon/minus.svg'}
            variants={{
              open: {
                height: 'auto',
                opacity: 1,
              },
              closed: {
                height: 0,
                opacity: 0,
                overflow: 'hidden',
              },
            }}
          />
          <MotionImage
            pos="absolute"
            src={'/landing/Icon/plus.svg'}
            variants={{
              open: {
                height: 0,
                opacity: 0,
              },
              closed: {
                height: 'auto',
                opacity: 1,
                overflow: 'hidden',
              },
            }}
          />
        </Center>
      </Flex>
      <MotionBox
        h={0}
        opacity={0}
        overflow="hidden"
        animate={animate}
        variants={{
          open: {
            height: 'auto',
            opacity: 1,
          },
          closed: {
            height: 0,
            opacity: 0,
            overflow: 'hidden',
          },
        }}
      >
        <Text fontSize={{ base: '0.875rem', md: '1rem' }} lineHeight="150%">
          {a}
        </Text>
      </MotionBox>
    </MotionVStack>
  )
}

export const FAQList: FC<BoxProps> = (props) => {
  const [currentOpened, setCurrentOpened] = useState<number | null>()

  return (
    <Frame py={{ base: '5rem', md: '10rem' }} {...props}>
      <Flex flexDir="column">
        <VStack spacing={2.5} align="flex-start">
          <Heading id="よくある質問" variant="main">
            FAQ
          </Heading>
          <Text variant="mainHeadingDescription">よくあるご質問</Text>
        </VStack>
        <Spacer flexBasis={{ base: '2rem', md: '3.5rem' }} />
        <VStack spacing="1.5rem">
          {faqList.map((data, i) => {
            return (
              <FAQ
                q={data[0]}
                a={data[1]}
                key={i}
                opened={currentOpened === i}
                onClick={() => {
                  setCurrentOpened((prev) => (prev == i ? null : i))
                }}
              />
            )
          })}
        </VStack>
      </Flex>
    </Frame>
  )
}
