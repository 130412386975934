import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Collapse,
  Center,
  BoxProps,
  Spacer,
} from '@chakra-ui/react'
import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { App } from 'src/api/firebase'
import { routes } from 'src/constants/routes'
import { FRAME_BREAKPOINT, Frame } from '../../Landing/index'
import { css } from '@emotion/react'

export const HEADER_HEIGHT_BREAKPOINT = { base: '5rem', md: '6rem' }

const HeaderPopupMenu: React.FC<{ onClose: () => void }> = (props) => {
  return (
    <Box bg="white" w="100vw">
      <Frame>
        <VStack spacing="3rem" py="2.5rem" align="flex-start">
          <Link
            _hover={{
              color: 'inherit',
            }}
            href="/#利用シーン"
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="none"
            onClick={props.onClose}
          >
            利用シーン
          </Link>
          <Link
            _hover={{
              color: 'inherit',
            }}
            href="/#機能"
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="none"
            onClick={props.onClose}
          >
            機能
          </Link>
          <Link
            _hover={{
              color: 'inherit',
            }}
            href="/#価格"
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="none"
            onClick={props.onClose}
          >
            価格
          </Link>
          <Link
            _hover={{
              color: 'inherit',
            }}
            href={routes.help}
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="none"
            onClick={() => {
              App.analytics.logEvent('help_manual_click')
              props.onClose
            }}
          >
            <HStack spacing={'1rem'} w="full">
              <span>使い方</span>
              <FaExternalLinkAlt />
            </HStack>
          </Link>
          <Link
            _hover={{
              color: 'inherit',
            }}
            href="/#よくある質問"
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="none"
            onClick={props.onClose}
          >
            よくある質問
          </Link>
          <Box width="80%">
            <Button
              colorScheme="black"
              variant="solid"
              w="100%"
              h="3.5rem"
              borderRadius="2rem"
              onClick={() => {
                window.location.href = '/#お問い合わせ'
              }}
              fontWeight="bold"
              fontSize="0.875rem"
              lineHeight="none"
              color="white"
            >
              お問い合わせ
            </Button>
          </Box>
        </VStack>
      </Frame>
    </Box>
  )
}

const MenuButton: React.FC<{
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}> = (props) => {
  return (
    <VStack
      spacing="0.4375rem"
      pt="1rem"
      bg="primary"
      boxSize="3.5rem"
      borderRadius="full"
      top="0.75rem"
      right={FRAME_BREAKPOINT}
      onClick={props.isOpen ? props.onClose : props.onOpen}
      cursor="pointer"
    >
      {props.isOpen ? (
        <>
          <Image src="/landing/Icon/menu-cross.svg" boxSize="1rem" />
          <Text
            color="white"
            fontFamily="Rubik"
            fontWeight="medium"
            fontSize="0.625rem"
            lineHeight="none"
            cursor="pointer"
          >
            Close
          </Text>
        </>
      ) : (
        <>
          <Center h="1rem">
            <VStack spacing="0.25rem">
              <Box
                bg="white"
                w="1.25rem"
                h="0.125rem"
                borderRadius="full"
              ></Box>
              <Box
                bg="white"
                w="1.25rem"
                h="0.125rem"
                borderRadius="full"
              ></Box>
              <Box
                bg="white"
                w="1.25rem"
                h="0.125rem"
                borderRadius="full"
              ></Box>
            </VStack>
          </Center>
          <Text
            color="white"
            fontFamily="Rubik"
            fontWeight="medium"
            fontSize="0.625rem"
            lineHeight="none"
            cursor="pointer"
          >
            Menu
          </Text>
        </>
      )}
    </VStack>
  )
}

export const Header: React.FC<{
  w?: BoxProps['w']
}> = (props) => {
  const hasPopupMenu = useBreakpointValue({ base: true, md: false }, 'md')
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box
      w={props.w != null ? props.w : '100%'}
      pos="fixed"
      zIndex="sticky"
      bg="white"
      css={css`
        @media print {
          position: static !important;
        }
      `}
    >
      <Frame h={{ base: '5rem', md: '6rem' }}>
        <Flex h="100%" justify="space-between" align="center">
          <Link href={routes.landing}>
            <Image src="/landing/Logo/Logo.svg" h="1.875rem" cursor="pointer" />
          </Link>
          <Flex display={{ base: 'none', md: 'block' }} flexShrink={0}>
            <Center flexDir="row">
              <Link
                _hover={{
                  color: 'inherit',
                }}
                py="1rem"
                px={{ base: '1rem', lg: '1.25rem' }}
                href="/#利用シーン"
                fontWeight="normal"
                fontSize="0.875rem"
                lineHeight="none"
                flexShrink={0}
              >
                利用シーン
              </Link>
              <Link
                _hover={{
                  color: 'inherit',
                }}
                py="1rem"
                px={{ base: '1rem', lg: '1.25rem' }}
                href="/#機能"
                fontWeight="normal"
                fontSize="0.875rem"
                lineHeight="none"
                flexShrink={0}
              >
                機能
              </Link>
              <Link
                _hover={{
                  color: 'inherit',
                }}
                py="1rem"
                px={{ base: '1rem', lg: '1.25rem' }}
                href="/#価格"
                fontWeight="normal"
                fontSize="0.875rem"
                lineHeight="none"
                flexShrink={0}
              >
                価格
              </Link>
              <Link
                _hover={{
                  color: 'inherit',
                }}
                py="1rem"
                px={{ base: '1rem', lg: '1.25rem' }}
                href={routes.help}
                fontWeight="normal"
                fontSize="0.875rem"
                lineHeight="none"
                flexShrink={0}
                onClick={() => {
                  App.analytics.logEvent('help_manual_click')
                }}
              >
                使い方
              </Link>
              <Link
                _hover={{
                  color: 'inherit',
                }}
                py="1rem"
                px={{ base: '1rem', lg: '1.25rem' }}
                href="/#よくある質問"
                fontWeight="normal"
                fontSize="0.875rem"
                lineHeight="none"
                flexShrink={0}
              >
                よくある質問
              </Link>
              <Flex w="1rem" />
              <Box>
                <Button
                  colorScheme="black"
                  variant="solid"
                  px="1.75rem"
                  h="3rem"
                  borderRadius="2rem"
                  onClick={() => {
                    window.location.href = '/#お問い合わせ'
                  }}
                  fontWeight="bold"
                  fontSize="0.875rem"
                  lineHeight="none"
                  color="white"
                >
                  お問い合わせ
                </Button>
              </Box>
            </Center>
          </Flex>
          <Box display={{ base: 'block', md: 'none' }}>
            <MenuButton isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          </Box>
        </Flex>
      </Frame>
      <Collapse in={hasPopupMenu !== false && isOpen} animateOpacity>
        <HeaderPopupMenu onClose={onClose} />
      </Collapse>
    </Box>
  )
}
