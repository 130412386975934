import { Box, Center, Flex, Image, Img, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Span, Video } from '../components'
import { Frame } from '../index'

export const MainVisual = () => {
  const HEIGHT = { base: '107vw', sm: '90vw', md: '42rem' }

  return (
    <Box pos="relative" h={HEIGHT}>
      <Flex justify="center" align="flex-start" overflow="hidden" h={HEIGHT}>
        <Video
          bg="#DDE2E1"
          loop
          autoPlay
          muted
          playsInline
          h={{ ...HEIGHT, xl: 'auto' }}
          minH={HEIGHT}
          w={{ base: '100vw', xl: 'calc(100vw - 20rem)' }}
          objectFit="cover"
          pos="relative"
          top="0rem"
        >
          <source src="/landing/Movie/top.webm" />
          <source src="/landing/Movie/top.mp4" />
        </Video>
      </Flex>
      <Frame
        pos="absolute"
        top="50%"
        left={0}
        h="50%"
        w="full"
        opacity="0.7"
        bgGradient="linear(to-b, transparent, black)"
      />
      <Frame pos="absolute" top={0} left={0} h="full" w="full">
        <VStack
          h="full"
          flexDir="column-reverse"
          alignItems="flex-start"
          spacing={0}
          py={['1rem', '3rem', '4rem', '5rem', '6rem']}
          px={[0, 0, '1rem']}
          // 見づらかったので ref: https://getcssscan.com/css-box-shadow-examples
          textShadow="rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
        >
          <Flex wrap="wrap">
            <Text
              color="white"
              fontWeight="bold"
              fontSize={['1.125rem', '1.25rem', '1.5rem']}
            >
              Microsoft Teams・zoom・Google Meet連携 自動で議事録作成
            </Text>
          </Flex>
          <Text
            fontSize={['1.125rem', '1.25rem', '1.5rem']}
            fontWeight="bold"
            color="white"
          >
            AIとの共同作業で議事録作成を圧倒的に効率化しましょう。
          </Text>

          <Text
            fontSize={['2.5rem', '3.5rem', '4.5rem']}
            fontWeight="black"
            lineHeight="125%"
            color="white"
            mb="1rem !important"
          >
            <Span>議事録は</Span>
            <br />
            AIにおまかせ
          </Text>
        </VStack>
      </Frame>
      <Frame pos="absolute" top={0} left={0} h="full" w="full">
        <VStack
          h="full"
          flexDir="row-reverse"
          alignItems="flex-end"
          spacing={0}
          py={{ base: '12.5rem', md: '16rem', lg: '11rem', '2xl': '6rem' }}
        >
          <Img
            src="/landing/Image/auto-summarize.png"
            borderRadius="10px"
            maxW={{ base: '40%', md: '300px', '2xl': '400px' }}
            boxShadow="lg"
          />
        </VStack>
      </Frame>
    </Box>
  )
}
